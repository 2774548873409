// src/utils/audioHelper.js
export function playAudio(filePath) {
    const audio = new Audio(filePath);
    audio.play().catch(error => console.error("Audio play failed:", error));
}

export function successVoice() {    
    playAudio('/audio/barcode_scan/success.mp3');
}

export function errorVoice() {
    playAudio('/audio/barcode_scan/not_found.mp3');
}

export function open() {
    playAudio('/audio/barcode_scan/open.mp3');
}